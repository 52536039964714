<template>
    <div
        class="request-comment request-comment_light"
        :class="{
            'request-comment_dark': isFirstComment,
            'request-comment_border-light': isUserAuthorOfComment,
        }"
    >
        <div class="request-comment__header">
            <UserCardWithPopover
                :account="comment.author"
                :status="$_toLocalDate(comment.createdAt)"
            ></UserCardWithPopover>
        </div>

        <p class="request-comment__content" v-if="chunks && chunks.length > 0">
            <template v-for="(chunk, index) in chunks">
                <span
                    class="request-comment__content-chunk"
                    :key="index"
                    v-text="chunk"
                    v-linkified:options="{
                        validate: validate,
                        defaultProtocol: 'https',
                    }"
                    v-if="chunk"
                ></span>

                <br :key="index + '-br'" />
            </template>
        </p>
        <p class="request-comment__content-edited" v-if="comment.createdAt != comment.updatedAt">
            <span>(изменено)</span>
        </p>

        <ul class="request-comment__attachments" v-if="comment.files && comment.files.length > 0">
            <template v-for="(attachment, index) in attachments">
                <li class="request-comment__attachment" :key="index">
                    <ChatCommentAttachment :dark="isFirstComment" :attachment="attachment"></ChatCommentAttachment>
                </li>
            </template>
        </ul>
    </div>
</template>
<script>
// Components
import UserCardWithPopover from '@/views/components/VUser/UserCardWithPopover.vue';

import ChatCommentAttachment from './ChatCommentAttachment.vue';

// Other
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import RequestCommentType from '../../../../js/enums/RequestCommentType';

@Component({
    components: {
        UserCardWithPopover,
        ChatCommentAttachment,
    },
})
class ChatComment extends Vue {
    @Prop({ type: Object, required: true })
    request;

    @Prop({ type: Object, required: true })
    comment;

    validate(uri) {
        if (typeof uri == 'string') {
            return uri.match(/^https:\/\/[a-zA-Z0-9][a-zA-Z0-9-]+\.talan\.group/);
        }

        return false;
    }

    get chunks() {
        if (!this.comment?.text) {
            return [];
        }

        return this.comment?.text
            .replace(/<span class='chat-text-color-blue'>|<\/span>/g, '') //bug fix
            .replace('<br />', '\n')
            .split('\n');
    }

    get attachments() {
        return this.comment.files;
    }

    get isFirstComment() {
        // If a comment is the first comment, we should highlight it.
        return this.comment.type == RequestCommentType.FIRST_COMMENT;
    }

    get isUserAuthorOfComment() {
        return this.comment.authorId == this.$_account.id;
    }
}

export default ChatComment;
</script>
<style lang="scss">
:root {
    --request-comment-dark-color: #fff;
    --request-comment-dark-background: #484848;

    --request-comment-light-color: #484848;
    --request-comment-light-background: #f8f8f8;

    --request-comment-border-light-border: #e4eaef;
    --request-comment-border-light-background: #fff;
}

.request-comment {
    display: inline-block;
    min-width: 328px;
    max-width: 75%;
    margin: 0 24px;
    padding: 16px;
    border-radius: 10px;

    &_light {
        color: var(--request-comment-light-color);
        background: var(--request-comment-light-background);
    }

    &_border-light {
        border: 1px solid var(--request-comment-border-light-border);
        background: var(--request-comment-border-light-background);
    }

    &_dark {
        color: var(--request-comment-dark-color);
        background: var(--request-comment-dark-background);

        & .user-card__label-name {
            color: var(--request-comment-dark-color);
        }
    }

    &__content,
    &__attachment {
        margin: 8px 0 0 0;
    }

    &__content-chunk {
        word-wrap: break-word;
        line-height: 1.4;
    }

    &__content-edited {
        font-size: 0.75rem;
        line-height: 0.875rem;
        color: var(--user-card-label-status-color);
        text-align: right;
        margin: 0;
    }
}
</style>
