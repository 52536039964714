<template>
    <div class="request-chat">
        <VueCustomScrollbar
            ref="scrollbar"
            suppress-scroll-x
            @ps-scroll-up="chatScrollUp"
            @ps-y-reach-end="chatScrollReachEnd"
            @ps-scroll-down="chatScrollDown"
        >
            <ul class="request-chat__comments">
                <template v-for="comment in comments">
                    <li
                        class="request-chat__comment"
                        :class="{
                            'request-chat__comment_right': isUserAuthorOfComment(comment),
                            'request-chat__comment_unread': !isCommentRead(comment),
                        }"
                        :key="comment.id"
                        :ref="`chatComment_${comment.id}`"
                        @mouseenter ="showMenuButton(comment.id)"
                        @mouseleave ="closeContextMenu"
                    >
                        <ChatComment :request="request" :comment="comment"></ChatComment>
                        <div class="request-chat__comment_edit">
                            <button
                                type="button"
                                class="request-chat__comment_edit-button"
                                v-show="isAllowToEdit(comment) && activeCommentId === comment.id && isMenuButtonVisible"
                                @click.stop="showContextMenu(comment.id)"
                            >
                                <DotsMenuSvg :size="20"></DotsMenuSvg>
                            </button>
                            <div
                                class="request-chat__comment_edit-menu"
                                v-show="isMenuVisible && activeCommentId === comment.id"
                                @click="editComment(comment)"
                            >
                                <span>Редактировать комментарий</span>
                            </div>
                        </div>
                    </li>
                </template>
            </ul>
        </VueCustomScrollbar>

        <div class="chat-control">
            <div
                class="chat-control__row chat-control__row_textarea"
                :class="{
                    'chat-control__row_textarea-stripped': attachments.length > 0,
                }"
            >
                <div class="chat-control__actions">
                    <button
                        type="button"
                        class="chat-control__action"
                        v-tooltip="'Отправить задачу в Bitrix24'"
                        @click.stop="$emit('sendToBitrix24')"
                    >
                        <Bitrix24Svg></Bitrix24Svg>
                    </button>

                    <button class="chat-control__action" @click="showFileUploader" v-tooltip="'Прикрепить файл'">
                        <PaperclipSvg></PaperclipSvg>

                        <FileUploader ref="uploader" v-model="attachments" v-show="false"></FileUploader>
                    </button>
                </div>

                <div class="chat-control__textarea">
                    <VContenteditable
                        class="chat-control__contenteditable"
                        :showSubmitButton="showSubmitButton"
                        :allow-submit="isAllowSubmit"
                        @submit="submit"
                        v-model="comment"
                        :request="request"
                        :showEditButton="showEditButton"
                        @edit="edit"
                        @close="closeEdit"
                        @keydown.escape.exact.prevent="handleEscKeyDown"
                        @keydown.up.exact.prevent="editLast"
                        ref="input"
                    ></VContenteditable>
                </div>
            </div>

            <VueCustomScrollbar
                class="chat-control__row chat-control__row_attachments"
                suppress-scroll-y
                v-if="attachments.length > 0"
            >
                <ul class="chat-control__attachments">
                    <template v-for="(attachment, index) in attachments">
                        <li class="chat-control__attachment" :key="index">
                            <ChatCommentAttachment
                                allow-deletion
                                :attachment="attachment"
                                @delete="deleteAttachment"
                            ></ChatCommentAttachment>
                        </li>
                    </template>
                </ul>
            </VueCustomScrollbar>
        </div>
    </div>
</template>
<script>
// Components
import VueCustomScrollbar from 'vue-custom-scrollbar';
import UserCard from '@/views/components/VUser/UserCard.vue';
import FileUploader from '@/views/components/FileUploader/FileUploader.vue';
import VContenteditable from '@/views/components/VContenteditable/VContenteditable.vue';

import RequestStatusType from '@/js/enums/RequestStatusType';

import ChatComment from './ChatComment.vue';
import ChatCommentAttachment from './ChatCommentAttachment.vue';
import RequestCommentType from '@/js/enums/RequestCommentType';

import DotsMenuSvg from 'vue-material-design-icons/DotsHorizontal.vue';
import HistorySvg from '@/images/history.svg';
import Bitrix24Svg from '@/images/bitrix24.svg';
import PaperclipSvg from '@/images/paperclip.svg';
import UserGroupSvg from '@/images/user-group.svg';
import last from 'lodash/last';

// Other
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Ref, Watch } from 'vue-property-decorator';

@Component({
    components: {
        UserCard,
        FileUploader,
        VContenteditable,
        VueCustomScrollbar,

        ChatComment,
        ChatCommentAttachment,

        HistorySvg,
        Bitrix24Svg,
        PaperclipSvg,
        UserGroupSvg,
        DotsMenuSvg,
    },
})
class RequestViewTabChat extends Vue {
    @Ref('uploader')
    uploader;

    @Prop({ type: Object, required: true })
    request;

    isScrollInEnd = true;
    isMenuButtonVisible = false;
    isMenuVisible = false;
    showEditButton = false;
    showSubmitButton = true;

    comment = null;
    activeCommentId = null;
    attachments = [];

    submit() {
        if (RequestStatusType.FINISH === this.request.status && this.request.authorId === this.$_account.id) {
            this.$emit('acceptWorkModal');
        }
        try {
            const files = this.attachments.map((attachment) => attachment.id);
            const comment = this.comment;

            if (this.isAllowSubmit) {
                this.$emit('addComment', { files, comment });
            }
        } finally {
            this.comment = null;
            this.attachments = [];
        }
    }

    deleteAttachment(attachment) {
        const index = this.attachments.indexOf(attachment);

        if (index !== -1) {
            this.attachments.splice(index, 1);
        }
    }

    showFileUploader() {
        if (this.uploader?.showFileUploader) {
            this.uploader.showFileUploader();
        }
    }

    chatScrollUp() {
        this.isScrollInEnd = false;
    }

    chatScrollReachEnd() {
        this.isScrollInEnd = true;
    }

    chatScrollDown() {
        const scrollTop = this.$refs.scrollbar.$el.scrollTop;
        const scrollHeight = this.$refs.scrollbar.$el.clientHeight;
        for (let i = this.comments.length - 1; i >= 0; i--) {
            if (this.isCommentRead(this.comments[i])) {
                break;
            }
            const messageComponent = this.$refs[`chatComment_${this.comments[i].id}`][0];
            if (messageComponent.offsetTop <= scrollTop + scrollHeight) {
                this.$emit('readComment', this.comments[i]);
            }
        }
    }

    scrollToEnd() {
        const element = this.$refs.scrollbar?.$el;

        if (element) {
            element.scrollTop = element.scrollHeight;
        }
    }

    isUserAuthorOfComment(comment) {
        return comment.authorId == this.$_account.id;
    }

    isCommentRead(comment) {
        const lastSeenCommentId = last(this.request.lastSeenComments)?.commentId || 0;
        if (comment.type === RequestCommentType.FIRST_COMMENT && comment.authorId === this.$_account.id) {
            return true;
        } else {
            return comment.id <= lastSeenCommentId;
        }
    }

    isAllowToEdit(comment){
        return (this.isUserAuthorOfComment(comment) &&
            !(comment.type === RequestCommentType.FIRST_COMMENT) &&
            !(this.comment));
    }

    get comments() {
        return this.request.comments ?? [];
    }

    get isAllowSubmit() {
        const hasNotUploadedAttachments = this.attachments
            .filter((attachment) => !attachment.errorMessage)
            .every((attachment) => attachment.id > 0);

        const hasAnySuccessfulUploadedAttachment =
            this.attachments.filter((attachment) => attachment.id > 0).length > 0;

        return (this.comment || hasAnySuccessfulUploadedAttachment) && hasNotUploadedAttachments;
    }

    @Watch('request.id')
    requestIdWatcher(newValue, oldValue) {
        oldValue = parseInt(oldValue, 10);
        newValue = parseInt(newValue, 10);

        if (oldValue !== newValue) {
            this.comment = null;
            this.activeCommentId = null;
            this.showEditButton = false;
            this.showSubmitButton = true;
            this.attachments = [];
        }
    }

    @Watch('request.comments')
    async requestUpdateWatcher() {
        if (this.isScrollInEnd) {
            await this.$nextTick();
            this.scrollToEnd();
        }
    }

    showMenuButton(commentId){
        this.isMenuButtonVisible = true;
        this.activeCommentId = commentId;
    }

    showContextMenu(commentId){
        this.isMenuVisible = true;
        this.activeCommentId = commentId;
        document.addEventListener('click', this.closeContextMenu);
    }

    closeContextMenu() {
        this.isMenuButtonVisible = false
        this.isMenuVisible = false;
        document.removeEventListener('click', this.closeContextMenu);
    }

    closeEdit(){
        this.comment = null;
        this.activeCommentId = null;
        this.showEditButton = false;
        this.showSubmitButton = true;
    }

    editComment(editableComment){
        this.comment = editableComment.text;
        this.activeCommentId = editableComment.id;
        this.showEditButton = true;
        this.showSubmitButton = false;
        this.$refs.input.focus();
    }

    edit(value){
        const newText = value;
        const commentId = this.activeCommentId;
        this.$emit('editComment', { commentId, newText });
        this.comment = null;
        this.activeCommentId = null;
        this.showEditButton = false;
        this.showSubmitButton = true;
    }

    editLast(){
        const lastComment = last(this.comments);
        if (this.isAllowToEdit(lastComment)){
            this.editComment(lastComment);
        }
    }

    handleEscKeyDown(){
        this.showEditButton ? this.closeEdit() : null;
    }

    mounted() {
        this.requestUpdateWatcher();
    }
}

export default RequestViewTabChat;
</script>
<style lang="scss">
:root {
    --chat-control-shadow: rgba(88, 88, 88, 0.15);
    --chat-control-action-fill: #aaaaaa;
    --chat-control-action-fill-hover: #484848;
    --chat-control-action-fill-active: #00a55a;
}

.request-chat {
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;

    &__comments {
        margin: 24px 0;
        min-height: none;
    }

    &__comment {
        display: flex;
        max-height: 100%;
        padding: 12px 0 12px 0;
        justify-content: flex-start;
        position: relative;

        &_right {
            justify-content: flex-end;
        }

        &_unread {
            background: #f0f0f0;
        }

        &_edit{
            position: absolute;
            top: 0;
            &-button{
                position: relative;
                display: flex;
                align-items: center;
                justify-content: center;
                width: 30px;
                height: 30px;
                margin: 0 15px;
                padding: 8px;
                fill: var(--chat-control-action-fill);
                background-color: var(--color-white);
                border: 1px solid var(--chat-control-action-fill);
                border-radius: 8px;
                cursor: pointer;
                &:hover,
                &:focus-visible {
                    fill: var(--chat-control-action-fill-hover);
                    border: 1px solid var(--chat-control-action-fill-hover);
                }
            }

            &-menu{
                position: absolute;
                top: 0;
                right:0;
                display: flex;
                align-items: center;
                width: 250px;
                min-height: 40px;
                padding: 0 16px;
                cursor: pointer;
                border-radius: 10px;
                background-color: var(--color-white);
                box-shadow: 0px 2px 15px 3px rgba(44, 44, 44, 0.12);
                color: var(--text-black);
                margin: 0 50px;
                &:hover {
                    background: #f8f8f8;
                }
            }
        }
    }
}

.chat-control {
    padding: 24px;
    max-height: 100%;
    box-shadow: 0px 1px 10px var(--chat-control-shadow);
    box-sizing: control-box;

    &__row {
        display: flex;
        align-items: flex-end;

        &_textarea {
            min-height: 72px;
            max-height: 100%;
        }

        &_textarea-stripped {
            max-height: calc(100% - 72px);
        }

        &_attachments {
            height: auto !important;
            min-height: 72px;
        }
    }

    &__action {
        width: 38px;
        height: 38px;
        margin: 5px;
        padding: 8px;
        fill: var(--chat-control-action-fill);
        border: 1px solid var(--chat-control-action-fill);
        border-radius: 8px;
        cursor: pointer;

        &:hover,
        &:focus-visible {
            fill: var(--chat-control-action-fill-hover);
            border: 1px solid var(--chat-control-action-fill-hover);
        }

        &:active {
            fill: var(--chat-control-action-fill-active);
            border: 1px solid var(--chat-control-action-fill-active);
        }
    }

    &__actions {
        flex: 0 0 96px;
        width: 96px;
        height: 48px;
        margin: -5px;
    }

    &__textarea {
        width: 100%;
        min-height: 72px;
        max-height: 100%;
        margin-left: 16px;
    }

    &__attachment {
        margin: 0 8px 0 0;
    }

    &__attachments {
        display: flex;
        margin: 8px 0 0 0;
    }
}
</style>
