<template>
    <div class="contenteditable"
        @keydown.enter.exact.prevent="handleKeyDown">
        <div
            ref="element"
            class="contenteditable__input"
            tabindex="0"
            aria-label="Редактор коментарии"
            contenteditable
            v-on="listeners"
        >
            <br />
        </div>

        <button
            type="button"
            class="contenteditable__submit"
            :class="{ contenteditable__submit_active: isAllowedToSubmit }"
            @click="submit"
            v-tooltip="'Отправить'"
            v-if="showSubmitButton && !showEditButton"
        >
            <ArrowRightSvg></ArrowRightSvg>
        </button>

        <button
            type="button"
            class="contenteditable__close-edit"
            @click="$emit('close')"
            v-tooltip="'Выйти'"
            v-if="showEditButton && !showSubmitButton"
        >
            <CloseEditIcon></CloseEditIcon>
        </button>

        <button
            type="button"
            class="contenteditable__edit"
            @click="$emit('edit', value)"
            v-tooltip="'Редактировать'"
            v-if="showEditButton && !showSubmitButton"
        >
            <EditIcon fillColor="white"></EditIcon>
        </button>

        <span class="contenteditable__placeholder" @click="focus" v-if="!value">{{ placeholder }}</span>
    </div>
</template>
<script>
// Svg
import ArrowRightSvg from '@/images/arrow-right.svg';
import EditIcon from 'vue-material-design-icons/Pencil';
import CloseEditIcon from 'vue-material-design-icons/Close';

// Other
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Ref, Watch } from 'vue-property-decorator';

@Component({
    components: {
        ArrowRightSvg,
        EditIcon,
        CloseEditIcon,
    },
})
class VContenteditable extends Vue {
    @Ref() element;

    @Prop({ type: String, default: '' })
    name;

    @Prop({ type: [String, Number] })
    value;

    @Prop({ type: Boolean, default: false })
    showSubmitButton;

    @Prop({ type: Boolean, default: false })
    showEditButton;

    @Prop({ type: [Function, Boolean], default: (value) => !!value })
    allowSubmit;

    @Prop({ type: String, default: 'Напишите сообщение...' })
    placeholder;

    handleKeyDown(){
        this.showSubmitButton ? this.submit() : this.$emit('edit', this.value);
    }

    focus() {
        setTimeout(() => {
            if (this.element) {
                this.element.focus();
                if (this.showEditButton){
                    let sel = window.getSelection();
                    sel.selectAllChildren(this.$refs.element);
                    sel.collapseToEnd();
                }
            }
        });
    }

    submit() {
        if (this.isAllowedToSubmit) {
            this.$emit('submit', this.value);
        }
    }

    getContent() {
        let value = this.$refs.element ? this.$refs.element.innerText : '';

        if (value === '\n') {
            value = '';
        }

        return value;
    }

    setContent(content) {
        content = content ?? '';

        if (this.$refs.element && typeof content === 'string') {
            this.$refs.element.innerText = content;
        }
    }

    onPaste(event) {
        event.preventDefault();
        event = event.originalEvent || event;

        const text = event.clipboardData
            .getData('text/plain')
            .replace('\r\n', ' ')
            .replace('\n', ' ')
            .replace('\r', ' ');

        document.execCommand('insertText', false, text);
    }

    wrap(parent, callback) {
        return (event) => {
            if (typeof parent === 'function') {
                parent(event);
            }

            if (typeof parent === 'function') {
                callback(event);
            }
        };
    }

    update() {
        const content = this.getContent();
        if (content != this.value) {
            this.$emit('input', content);
        }
    }

    @Watch('value')
    valueWatcher(value) {
        if (value != this.getContent()) {
            this.setContent(value);
        }
    }

    get isAllowedToSubmit() {
        return typeof this.allowSubmit == 'function' ? this.allowSubmit(this.value) : this.allowSubmit;
    }

    get listeners() {
        this.$listeners.blur = this.wrap(this.$listeners.blur, this.update);
        this.$listeners.input = this.wrap(this.$listeners.input, this.update);

        this.$listeners.paste = this.onPaste;

        return this.$listeners;
    }

    mounted() {
        this.setContent(this.value);
    }
}

export default VContenteditable;
</script>
<style lang="scss">
:root {
    --contenteditable-color: #484848;
    --contenteditable-placeholder-color: #808080;
    --contenteditable-background: #f8f8f8;
    --contenteditable-border-error: var(--error-color);
    --contenteditable-border-active: #349bfb;

    --contenteditable-submit-background: #dedddd;
    --contenteditable-submit-background-ready: var(--color-green);
    --contenteditable-submit-background-hover: #15754a;
    --contenteditable-submit-background-active: var(--color-green);
}

.contenteditable {
    display: flex;
    width: 100%;
    position: relative;

    &.disabled,
    &[disabled],
    &[disabled='disabled'] {
        pointer-events: none;
    }

    &.error {
        & .contenteditable__input {
            transition: 0.06s;
            border-color: var(--contenteditable-border-error);
        }
    }

    &__input,
    &__placeholder {
        font-size: 0.875rem;
        line-height: 1.2rem;
        color: var(--contenteditable-color);
        user-select: none;
    }

    &__input {
        display: inline;
        width: 100%;
        min-height: 72px;
        margin: -1px 0;
        padding: 16px 16px;
        overflow-y: auto;
        border: none;
        outline: none;
        background: var(--contenteditable-background);
        word-break: break-word;
        border-radius: 10px;
        border: 1px solid var(--contenteditable-background);
        transition: 0.06s;
        -ms-overflow-style: none;
        overflow: -moz-scrollbars-none;

        &::-webkit-scrollbar {
            width: 0;
        }

        &:focus,
        &:active {
            transition: 0.06s;
            border-color: var(--contenteditable-border-active);
        }
    }

    &__submit {
        position: absolute;
        width: 24px;
        height: 24px;
        right: 12px;
        bottom: 12px;
        outline: none;
        border: 0;
        border-radius: 50%;
        background: var(--contenteditable-submit-background);
        cursor: pointer;
        z-index: 2;
        transition: 0.06s;

        &_active {
            background: var(--contenteditable-submit-background-ready);
            transition: 0.06s;

            &:hover,
            &:focus-visible {
                background: var(--contenteditable-submit-background-hover);
            }

            &:active {
                background: var(--contenteditable-submit-background-active);
            }
        }
    }

    &__edit{
        position: absolute;
        width: 24px;
        height: 24px;
        right: 12px;
        bottom: 12px;
        outline: none;
        border: 0;
        border-radius: 50%;
        background: var(--contenteditable-submit-background);
        cursor: pointer;
        z-index: 2;
        transition: 0.06s;
        background: var(--contenteditable-submit-background-active);
    }

    &__close-edit{
        position: absolute;
        width: 24px;
        height: 24px;
        right: 12px;
        bottom: 40px;
        outline: none;
        border: 0;
        border-radius: 50%;
        background: var(--contenteditable-submit-background);
        cursor: pointer;
        z-index: 2;
        transition: 0.06s;
    }

    &__placeholder {
        position: absolute;
        top: 16px;
        left: 17px;
        right: 16px;
        bottom: 16px;
        cursor: text;
        color: var(--contenteditable-placeholder-color);
        z-index: 1;
    }
}
</style>
